import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "date" }

import {toRefs} from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateFormat',
  props: {
  date: String
},
  setup(__props) {

const props = __props;
const {date} = toRefs(props);

function formatDate(timestamp: any) {
  const date = new Date(timestamp)
  return `${addLeadingZeros(date.getHours())}:${addLeadingZeros(date.getMinutes())} ${addLeadingZeros(date.getDate())}-${addLeadingZeros(date.getMonth())}-${date.getFullYear()}`
}

function addLeadingZeros(num: number) {
  return String(num).padStart(2, '0');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(formatDate(_unref(date))), 1))
}
}

})