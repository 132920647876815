import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "details center" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "card p-1 center"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "pending dummy width-restricted center m-t-1"
}

import { ref } from 'vue';
import { useRoute } from 'vue-router';
import DateFormat from "@/components/DateFormat.vue";
import { CacheService } from '@/services/cache.service';
import { DataModel } from '@/models/data.model';
import ErrorDisplay from '@/components/ErrorDisplay.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Details',
  setup(__props) {

const route = useRoute()
const uuid = String(route.params.uuid)

const data = ref<DataModel>()
const error = ref<string>()
CacheService.retrieveByUUID(uuid)
  .then(rsp => data.value = rsp.data)
  .catch(e => error.value = e.message)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Player Details", -1)),
    (error.value)
      ? (_openBlock(), _createBlock(ErrorDisplay, {
          key: 0,
          message: error.value,
          keyword: "details"
        }, null, 8, ["message"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (data.value)
            ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
                _createElementVNode("tbody", null, [
                  _createElementVNode("tr", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("th", null, "Icon", -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("img", {
                        src: data.value.avatar,
                        alt: data.value.tag
                      }, null, 8, _hoisted_4),
                      _createElementVNode("img", {
                        src: `https://visage.surgeplay.com/face/32/${data.value.uuid}`,
                        alt: data.value.name
                      }, null, 8, _hoisted_5)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("th", null, "Name", -1)),
                    _createElementVNode("td", null, _toDisplayString(data.value.name), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("th", null, "UUID", -1)),
                    _createElementVNode("td", null, _toDisplayString(data.value.uuid), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("th", null, "Discord ID", -1)),
                    _createElementVNode("td", null, _toDisplayString(data.value.discordId), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("th", null, "Discord Username", -1)),
                    _createElementVNode("td", null, _toDisplayString(data.value.tag), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("th", null, "Guild ID", -1)),
                    _createElementVNode("td", null, _toDisplayString(data.value.guildId), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("th", null, "Skin", -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("img", {
                        src: `https://visage.surgeplay.com/bust/64/${data.value.uuid}`,
                        class: "skin",
                        alt: "Player Skin"
                      }, null, 8, _hoisted_6)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("th", null, "Created At", -1)),
                    _createVNode(DateFormat, {
                      date: data.value.createdAt
                    }, null, 8, ["date"])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("th", null, "Statistics", -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", {
                        href: `https://plan.beocraft.net/player/${_unref(uuid)}/overview`,
                        target: "_blank"
                      }, "Click Here", 8, _hoisted_7)
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8))
        ]))
  ]))
}
}

})