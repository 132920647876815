import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-margin" }
const _hoisted_2 = { class: "no-margin" }

import { toRefs } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorDisplay',
  props: {
    message: String,
    keyword: String
},
  setup(__props) {

const props = __props
const { message, keyword } = toRefs(props)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, "Something went wrong while loading " + _toDisplayString(_unref(keyword)) + "!", 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(message)), 1)
  ]))
}
}

})