import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-black" }
const _hoisted_2 = { class: "center" }


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const year = new Date().getFullYear();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: "/online" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Online")
          ])),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/players" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Players")
          ])),
          _: 1
        }),
        _cache[5] || (_cache[5] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/about" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("About Us")
          ])),
          _: 1
        }),
        _cache[6] || (_cache[6] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/join" }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Join Now")
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_view),
    _createElementVNode("footer", _hoisted_2, [
      _createElementVNode("p", null, "© " + _toDisplayString(_unref(year)) + " Beocraft - All rights reserved", 1)
    ])
  ], 64))
}
}

})