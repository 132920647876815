import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "join" }

import {onBeforeUnmount} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Join',
  setup(__props) {

const timeout = setTimeout(() => {
  window.location.href = 'https://discord.gg/q3ZNyDeCcA'
}, 5000)
onBeforeUnmount(() => clearTimeout(timeout));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h3", null, "You will be redirected to our Discord server", -1),
    _createElementVNode("p", null, [
      _createTextVNode("If the redirect did not work please use "),
      _createElementVNode("a", { href: "https://discord.gg/q3ZNyDeCcA" }, "this link")
    ], -1),
    _createElementVNode("iframe", {
      src: "https://discord.com/widget?id=1088934690385317928&theme=dark",
      width: "350",
      height: "500",
      allowtransparency: "true",
      frameborder: "0",
      sandbox: "allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts",
      class: "center"
    }, null, -1)
  ])))
}
}

})