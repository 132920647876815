import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex flex-column width-restricted center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "mobile-hidden" }
const _hoisted_9 = {
  key: 1,
  class: "pending dummy width-restricted center m-t-1"
}

import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { DataService } from '@/services/data.service';
import { useRouter } from 'vue-router';
import ErrorDisplay from '@/components/ErrorDisplay.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Online',
  setup(__props) {

const status = ref<any>()
const error = ref<string>()
onBeforeMount(() => loadStatus())

function loadStatus() {
    DataService.getServerStatus().then(srsp => {
        status.value = srsp.data

        status.value.list.forEach((p: any) => {
            DataService.getDiscordMember(p.id).then(prsp => {
                p.discord = prsp.data.nickname
                p.avatar = prsp.data.avatar
            }).catch(e => error.value = e.message)
        })
    }).catch(e => error.value = e.message)
}

const router = useRouter()
function openDetails(uuid: string) {
    router.push({
        path: '/details/' + uuid
    })
}

// Auto reload status every 30 sec
const interval = setInterval(loadStatus, 30000)
onBeforeUnmount(() => clearInterval(interval));

return (_ctx: any,_cache: any) => {
  return (error.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Online Players", -1)),
        _createVNode(ErrorDisplay, {
          message: error.value,
          keyword: "online players"
        }, null, 8, ["message"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (status.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h3", null, "Currently " + _toDisplayString(status.value.online) + " out of " + _toDisplayString(status.value.max) + " players online", 1),
              _createElementVNode("main", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(status.value.list, (player) => {
                  return (_openBlock(), _createElementBlock("article", {
                    class: "flex flex-row flex-distribute card card-interactable table-element",
                    onClick: (e) => openDetails(player.id)
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: player.avatar
                      }, null, 8, _hoisted_6),
                      _createElementVNode("img", {
                        src: `https://visage.surgeplay.com/face/32/${player.id}`
                      }, null, 8, _hoisted_7)
                    ]),
                    _createElementVNode("span", null, _toDisplayString(player.name), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(player.discord), 1)
                  ], 8, _hoisted_5))
                }), 256))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9))
      ]))
}
}

})